.single-vehicle-container {
  .vin-details {
    box-sizing: border-box;
    .item {
      border: 1px solid var(--gray-primary);
      border-bottom: none;
      &:last-child {
        border-bottom: 1px solid var(--gray-primary);
      }
      .label {
        padding: 5px;
        font-weight: 500;
        width: 240px;
        padding-left: 10px;
      }
      .value {
        color: var(--blue-primary);
      }
    }
  }
}
.document-buttons {
  .btn {
    display: flex !important;
  }
}
.generate-form-modal {
  .form-row {
    margin-bottom: 20px;
  }
}
.vehicle-images {
  .image {
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
}
