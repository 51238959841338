.header {
  height: 56px;
  background-color: var(--mantine-color-body);
  border-bottom: 1px solid
    light-dark(var(--mantine-color-gray-3), var(--mantine-color-dark-4));
}

.inner {
  height: 56px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.link {
  display: block;
  line-height: 1;
  padding: 8px 12px;
  border-radius: var(--mantine-radius-sm);
  text-decoration: none;
  color: var(--mantine-color-blue-7);
  font-size: var(--mantine-font-size-sm);
  font-weight: 500;

  &:hover {
    color: var(--green-primary);
  }
}

.linkLabel {
  margin-right: 5px;
}
