html {
  scroll-behavior: smooth;
}
.flex-row {
  display: flex;
  flex-direction: row;
}
.flex-col {
  display: flex;
  flex-direction: column;
}
.align-center {
  align-items: center;
}
.justify-center {
  justify-content: center;
}
.justify-between {
  justify-content: space-between;
}
.align-end {
  align-items: flex-end;
}
.width-100 {
  width: 100%;
}
* {
  text-decoration: none;
}
.pointer {
  cursor: pointer;
}
body {
  overflow-y: scroll;
  overflow-x: clip;
  .tabs-root {
    border: 1px solid var(--green-primary);
    background-color: var(--white-primary);
    border-radius: 30px;
  }
  .tabs-control {
    width: 140px;
  }
  .tabs-indicator {
    background-color: var(--green-primary);
    border-radius: 30px;
  }
  .tabs-label {
    color: var(--green-primary);
    &[data-active] {
      color: var(--white-primary);
    }
  }

  .section-header {
    font-weight: 700;
    text-align: center;
    font-size: 35px;
    color: var(--black-primary);
    margin-bottom: 40px;
    margin-top: 30px;
    @media screen and (max-width: 900px) {
      font-size: 30px;
    }
    @media screen and (max-width: 600px) {
      font-size: 27px;
    }
    @media screen and (max-width: 500px) {
      font-size: 23px;
    }
  }
}
