// @keyframes Carnimation {
//   0% {
//     transform: scale(1);
//   }
//   50% {
//     transform: scale(0.95);
//   }
//   100% {
//     transform: scale(1);
//   }
// }
@keyframes Buttonate {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(1);
  }
}

.homepage-container {
  overflow-x: clip;
  padding-top: 20px;
  .jumbotron {
    background-color: var(--green-secondary);
    .content {
      height: 60vh;
      min-height: 600px;
      .left {
        .header {
          font-weight: 700;
          font-size: 50px;
        }
        .desc {
          font-size: 18px;
          line-height: 30px;
        }
        .btn {
          animation: Buttonate 3s infinite;
          border-radius: 50px;
        }
      }
      .right {
        animation: Carnimation 4s infinite;
        height: 400px;
        @media screen and (max-width: 1200px) {
          height: 350px;
        }
        @media screen and (max-width: 1000px) {
          height: 250px;
        }
      }
      @media screen and (max-width: 870px) {
        flex-direction: column-reverse;
        justify-content: space-evenly;
        min-height: 700px;
        .left {
          .header {
            font-size: 40px;
          }
        }
      }
      @media screen and (max-width: 470px) {
        .left {
          .header {
            font-size: 32px;
          }
        }
        .right {
          height: 180px;
        }
      }
    }
  }
  .unlock-potential {
    margin-top: 50px;

    .unlock-bg {
      background-image: url("../../assets/img/HandOnWheel.webp");
      height: 550px;
      width: 100vw;
      opacity: 0.2;
      z-index: -1;
      background-size: cover;
      background-position: center;
      position: absolute;
    }
    .unlock-item {
      width: 32%;
      @media screen and (max-width: 720px) {
        display: none;
      }
    }
    .unlock-slide {
      width: 100%;
    }
    .unlock {
      height: 360px;
      padding: 0px;
      .image {
        height: 220px;
        background-size: cover !important;
        background-repeat: no-repeat !important;
        background-position: center !important;
        width: 100%;
      }
      .title {
        font-size: 18px;
        font-weight: 600;
        padding: 0px 10px;
        padding-top: 10px;
        color: var(--black-primary);
      }
      .desc {
        font-size: 15.5px;
        padding: 0px 10px;
      }
      @media screen and (max-width: 1100px) {
        height: 400px;
      }
      @media screen and (max-width: 900px) {
        height: 450px;
      }
      @media screen and (max-width: 720px) {
        height: 350px;
      }
    }

    .unlock-indicator {
      width: 12px;
      height: 4px;
      transition: width 250ms ease;
      background-color: var(--green-primary);

      &[data-active] {
        width: 40px;
      }
    }
  }
}
