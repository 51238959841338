.navbar-container {
  width: 100%;
  position: fixed;
  z-index: 9;
  .logo {
    width: 60px;
    height: 60px;
  }
  .btn {
    border-radius: 50px;
  }
}
