.footer-container {
  .footer {
    margin-top: 120px;
    border-top: 1px solid
      light-dark(var(--mantine-color-gray-2), var(--mantine-color-dark-5));
  }

  .inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: var(--mantine-spacing-md) var(--mantine-spacing-md);
    .logo {
      height: 70px;
      width: 70px;
    }
    @media screen and (max-width: 560px) {
      flex-direction: column;
    }
  }

  .links {
    @media screen and (max-width: 560px) {
      margin-top: var(--mantine-spacing-lg);
      margin-bottom: var(--mantine-spacing-sm);
    }
  }
}
