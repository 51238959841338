.contact-us-container {
  padding-bottom: 50px;
  .btn {
    border-radius: 50px;
    height: 50px;
  }
  .cars-bg {
    width: 100%;
    height: 240px;
    background-image: url("../../assets/img/CarsArray.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 0.2;
    position: absolute;
    margin-top: -120px;
    z-index: -1;
  }
}
