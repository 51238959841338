:root {
  --green-primary: #0b9851;
  --green-secondary: #076938;
  --blue-primary: #1971c2;
  --black-primary: #1e1e1e;
  --gray-primary: #a6a6a6;
  --gray-secondary: #606060;
  --white-primary: #ffffff;
  --white-blue-transparent: #f1f8ff;
  --red-primary: var(--mantine-color-red-5);
}
