.start-container {
  padding: 60px 0px;
  .input {
    height: 50px;
    border-radius: 10px;
    text-transform: uppercase;
    font-weight: 500;
    color: var(--black-primary);
    &::placeholder {
      text-transform: capitalize;
      font-weight: 400;
    }
  }
  .input-wrapper {
    border-radius: 10px;
  }
  .media-container {
    .image {
      height: 100px;
      background-position: center !important;
      background-size: cover !important;
      background-repeat: no-repeat !important;
    }
  }
  .completed {
    .image {
      width: 260px;
      margin: 40px 0px;
    }
  }
  .information-policy {
    @media screen and (max-width: 620px) {
      max-width: 82vw;
    }
  }
}
